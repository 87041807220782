import React from 'react';
import { FormLayout, TextField, Text } from "@shopify/polaris";
import ButtonBlockItem from './components/ButtonBlockItem';
import update from 'immutability-helper';
import FieldTitle from '../../../../../components/fieldTitle';
const ButtonTab = (props) => {
  const {state, setState} = props;

  return (
    <FormLayout>
      <ButtonBlockItem {...props}/>
      <TextField
        label={
          <FieldTitle title="CTA button copy (optional)"/>
        }
        placeholder="Add to cart"
        onChange={(val) => setState(update(state, {cta: {$set: val}}))}
        value={state.cta}
        helpText={
          <span>
            Override the “Add to cart” CTA copy on your offers.
          </span>
        }
        autoComplete='false'
      />
    </FormLayout>
  );
};

export default ButtonTab;